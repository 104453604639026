// src/components/DiscordOauth.js
import React, { useEffect } from 'react';
import Preloader from './Preloader';

const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URI;
const scope = process.env.REACT_APP_DISCORD_CLIENT_SCOPE;

const DiscordOauth = () => {
  // console.log(clientId, redirectUri, scope);
  const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${scope}`;
  
  useEffect(() => {
    window.location.href = authUrl;
  });

  return (
    <Preloader show={true} message="Redirecting to Discord..." />
  );
};

export default DiscordOauth;
