
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faPlus, faCogs, faBinoculars, faSignOutAlt, faSignInAlt, faTimes, faRocket, faRefresh, faHome } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Navbar, Button, Modal } from 'react-bootstrap';
import UserAvatar from "../components/UserAvatar";

import useAxiosInstance from '../utils/axiosInstance';
import { useUser } from "../contexts/userContext";
import { Routes } from "../routes";


const Sidebar = (props = {}) => {
  const { token, isLoggedIn, name, plan, userData, pro, requestingCheckout, getCheckoutSession } = useUser();
  const [show, setShow] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  
  
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const handleClose = () => setShowUpgradeModal(false);
  const axios = useAxiosInstance(token);


  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <div>
          <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Dashboard.path}>
            <FontAwesomeIcon icon={faBinoculars} className="me-1 binoc-logo-icon" />
            <span className="ms-2 mb-3 brand-text">M R K T W T C H R</span>
          </Navbar.Brand>
          { pro && 
           <Badge bg="tertiary" className="d-md-none plan ms-2 px-2 py-1">{plan?.toUpperCase()}</Badge>
          }
        </div>
        <Navbar.Toggle as={Link} aria-controls="main-navbar" className="border-0" onClick={onCollapse}>
          {/* <span className="navbar-toggler-icon" /> */}
          <UserAvatar name={name} />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner mt-2 px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="me-4">
                  <UserAvatar name={name} />
                </div>
                <div className="d-block">
                { isLoggedIn ? <>
                  <h6>Hi, {name}!</h6>
                  <Button as={Link} variant="secondary" size="sm" to={"/logout"} onClick={onCollapse} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button></>
                :
                  <Button as={Link} variant="secondary" size="sm" to={"/login"} onClick={onCollapse} className="text-dark">
                    <FontAwesomeIcon icon={faSignInAlt} className="me-2" /> Sign In
                  </Button>
                }
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <div className="d-none d-md-flex">
                <Navbar.Brand as={Link} to="/" className="me-lg-3 d-flex align-items-center">
                  <div className="binoc-logo-icon"><FontAwesomeIcon icon={faBinoculars} className="mx-1 mb-3" /></div>
                  <span className="ms-2 mb-3 brand-text">M R K T W T C H R</span>
                </Navbar.Brand>
              </div>
              <div className="d-md-none"><NavItem title="Home" icon={faHome} link={Routes.Presentation.path} /></div>
              { isLoggedIn &&
                <>
                  <NavItem title="Dashboard" icon={faChartBar} link={Routes.Dashboard.path} />
                  <NavItem title="Add Alert" icon={faPlus} link={Routes.Alert.path} />
                  <NavItem title="Settings" icon={faCogs} link={Routes.Settings.path} />
                </> 
              }
              { !pro && <Button as={Link} onClick={getCheckoutSession} variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faRocket} className="me-1" /> Upgrade to PRO</Button> }
            </Nav>
          </div>
          <Modal as={Modal.Dialog} centered show={showUpgradeModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h6">Upgrade</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
              <div className="product">
                <div className="description">
                  <h3>MRKTWTCHR PRO</h3>
                  <h5>$10.00 / year</h5>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="text-gray" onClick={handleClose}>No thanks</Button>
              { ! requestingCheckout ? 
                <Button variant="secondary" className="ms-auto w-25" onClick={getCheckoutSession}>Lets do it!</Button>
              :
                <Button disabled={true} variant="secondary" className="ms-auto w-25">
                  <FontAwesomeIcon icon={faRefresh} className={`me-2 alert-refresh-icon${requestingCheckout ? '-spin' : ''}`} />
                </Button>
              }
            </Modal.Footer>
          </Modal>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
