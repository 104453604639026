import React, { useEffect } from "react";
import { Col, Row, Card} from 'react-bootstrap';
// import { SubscriptionCardWidget } from "../components/Widgets";
import { AlertForm } from "../components/Forms";
import { useUser } from "../contexts/userContext";
import { default as RequireAuth } from '../components/RequireAuth';


const AlertFormPage = () => {
  const { isLoggedIn } = useUser();

  useEffect(() => {
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm mb-2 pb-2 mt-3">
              <Card.Body>
              <h5 className="mb-4">Add Alert</h5>
              <AlertForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <RequireAuth />
    );
  }
};

export default AlertFormPage;