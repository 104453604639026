import React, { useEffect }  from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { GeneralInfoForm, NotificationForm } from "../components/Forms";
import { default as RequireAuth } from '../components/RequireAuth';

import { useUser } from "../contexts/userContext";



const Settings = () => {
  const { isLoggedIn } = useUser();

  if (isLoggedIn) {
    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
            <NotificationForm />
            <GeneralInfoForm />
            
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <RequireAuth />
    );
  }
};

export default Settings;
