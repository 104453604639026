// src/contexts/MessageBarContext.js
import React from 'react';
import { createContext, useContext, useState } from 'react';

const MessageBarContext = createContext();

const MessageBarProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [messageId, setMessageId] = useState(null);

  const showMessage = (msg, type='success', duration=5000) => {
    clearTimeout(messageId);
    setMessage(msg);
    setMessageType(type);

    const timeoutId = setTimeout(() => {
      setMessage(null);
      setMessageType(null);
    }, duration);

    setMessageId(timeoutId);
  };

  return (
    <MessageBarContext.Provider value={{ message, messageType, showMessage, setMessage, setMessageType }}>
      {children}
    </MessageBarContext.Provider>
  );
};

const useMessageBar = () => {
  return useContext(MessageBarContext);
};

export { MessageBarProvider, useMessageBar };
export default MessageBarContext;
