import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt  } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../contexts/userContext";
import { Col, Row, Button } from 'react-bootstrap';

const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useUser();

  return isLoggedIn !== true && (
    <Row className="justify-content-center my-5">
      <Col className="text-center">
      <h4>Please <Button href="/login" target="_same" variant="secondary">Sign in <FontAwesomeIcon icon={faSignInAlt} className="d-none d-sm-inline ms-1" /></Button> to view your <span className="fw-bolder">Dashboard</span></h4>
    </Col>
  </Row>  
  );
};

export default RequireAuth;
