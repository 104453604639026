import React from 'react';
import { Card, Accordion } from '@themesberg/react-bootstrap';


const PrivacyAccordion = () => {
  return (
    <Accordion.Item eventKey="privacy">
      <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
        <span className="h6 mb-0 fw-bold">
          Privacy Policy
        </span>
      </Accordion.Button>
      <Accordion.Body>
        <Card.Body className="py-2 px-0">
        <Card.Text className="mb-0">
          <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our notification service (the "Service"). Please read this Privacy Policy carefully. By accessing or using the Service, you agree to the collection and use of your personal information as described in this policy.</p>
          <strong>Information We Collect</strong>
          <ul>
            <li>
              Personal Information: When you create an account or use the Service, we collect your Reddit identity and may collect personal information such as your name, email address, account ID's and any other information you voluntarily provide.
            </li>
            <li>
              Usage Information: We may collect information about your interactions with the Service, including the alerts you set up and the preferences you select.
            </li>
            <li>
              Log Data: We automatically collect certain information when you access or use the Service. This may include your IP address, browser type, operating system, device information, and the pages or features of the Service that you visit.
            </li>
          </ul>
          <strong>Use of Information</strong>
          <ul>
            <li>
              We use the information we collect to provide, maintain, and improve the Service, as well as to personalize your experience.
            </li>
            <li>
              We may also use your information to communicate with you, respond to your inquiries, and send you important notifications and updates related to the Service.
            </li>
            <li>
              We may use aggregated and anonymized data for statistical purposes, research, and analytics to improve our Service.
            </li>
          </ul>
          <strong>Disclosure of Information</strong>
          <ul>
            <li>
              We may share your personal information with trusted third-party service providers who assist us in operating and maintaining the Service. These service providers are obligated to handle your information securely and in accordance with this Privacy Policy.
            </li>
            <li>
              We may disclose your information if required by law or in response to a valid legal request, such as a subpoena or court order.
            </li>
            <li>
              In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction. We will notify you via email and/or prominent notice on our website of any change in ownership or use of your personal information.
            </li>
          </ul>
          <strong>Data Security</strong>
          <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
          <strong>Third-Party Links and Services</strong>
          <p>The Service contains links to third-party websites or services, including Reddit and Discord, that are not owned or controlled by us. We are not responsible for the privacy practices or the content of such third-party websites or services. We encourage you to review the privacy policies of those third parties.</p>
          <strong>Children's Privacy</strong>
          <p>The Service is not intended for children. We do not knowingly collect personal information from children. If you believe we may have collected information from a child, please contact us immediately, and we will take appropriate steps to remove that information from our records.</p>
          <strong>Changes to this Privacy Policy</strong>
          <p>We reserve the right to update or modify this Privacy Policy at any time. If we make any material changes, we will notify you by posting the updated policy on our website or through other means of communication. Your continued use of the Service following the changes constitutes your acceptance of the revised Privacy Policy.</p>
          <strong>Contact Us</strong>
          <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us.</p>
          <p>By using our notification service, you agree to the terms of this Privacy Policy. Thank you for trusting us with your personal information!</p>
          </Card.Text>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
  )
};

const TermsAccordion = () => {
  return (
    <Accordion.Item eventKey="terms">
      <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
        <span className="h6 mb-0 fw-bold">
          Terms of Service
        </span>
      </Accordion.Button>
      <Accordion.Body>
      <p>Welcome to MRKTWTCHR! These terms of service (the "Terms") govern your use of our service, so please read them carefully before accessing or using our website or mobile application (collectively referred to as the "Service").</p>

      <p><strong>1. Acceptance of Terms</strong></p>

      <p>By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms or our Privacy Policy, you must not use the Service.</p>

      <p><strong>2. Description of Service</strong></p>

      <p>Our notification service allows users to log in and set up alerts based on specific criteria. You can receive notifications through various communication channels, such as Reddit, email or Discord.</p>

      <p><strong>3. User Accounts</strong></p>

      <p>To use the Service, you are required to create a user account by authorizing the MRKTWTCHR App to get your Reddit identity. Any other information may be provided voluntarily to enhance your MRKTWTCHR experience. You are solely responsible for maintaining the confidentiality of your account credentials, and you agree to notify us immediately of any unauthorized use of your account.</p>

      <p><strong>4. User Responsibilities</strong></p>

      <ul>
        <li><p><strong>a. Proper Use:</strong> You agree to use the Service only for its intended purpose and in compliance with all applicable laws and regulations. You shall not use the Service to engage in any illegal, harmful, or abusive activities.</p></li>
        <li><p><strong>c. Security:</strong> You are responsible for maintaining the security of your account and any devices used to access the Service. You must not share your account credentials with any third party.</p></li>
      </ul>

      <p><strong>5. Intellectual Property</strong></p>

      <p>All intellectual property rights in the Service, including but not limited to trademarks, logos, and content, are owned by us or our licensors. You may not use, reproduce, modify, or distribute any part of the Service without our prior written permission.</p>

      <p><strong>6. Limitation of Liability</strong></p>

      <ul>
        <li><p><strong>a. Disclaimer:</strong> We provide the Service on an "as is" and "as available" basis. We do not warrant that the Service will be uninterrupted, error-free, or secure. We disclaim all warranties, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p></li>
        <li><p><strong>b. Limitation of Liability:</strong> In no event shall we be liable for any direct, indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of the Service, even if we have been advised of the possibility of such damages.</p></li>
      </ul>

      <p><strong>7. Modification and Termination</strong></p>

      <p>We reserve the right to modify or terminate the Service, or these Terms, at any time without prior notice. Your continued use of the Service after any modifications to the Terms will constitute your acceptance of the modified Terms.</p>

      <p><strong>8. Governing Law and Jurisdiction</strong></p>

      <p>These Terms shall be governed by and construed in accordance with the laws of the United States. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of United States.</p>
      <p><strong>9. Contact Us</strong></p>
      <p>If you have any questions or concerns about these Terms or the Service, please contact us.</p>
      <p>By using our notification service, you agree to abide by these Terms. Thank you for using MRKTWTCHR!</p>

      </Accordion.Body>
    </Accordion.Item>
  )
};

const About = () => {
  return (
    <>
      <div className="pb-4 text-center">
        <h5>
          <strong>MRKTWTCHR</strong> started out as a script to watch for new posts that hit r/mechmarket and alert me when a specific keyset went up for sale. I realized this might be something others could benefit from, so I set out and built <strong>MRKTWTCHR</strong>. I hope you find it useful!
        </h5>
      </div>
      <Accordion flush className="pt-2" defaultActiveKey="">
        <TermsAccordion/>
        <PrivacyAccordion/>
      </Accordion>
    </>
  )
}

export default About;