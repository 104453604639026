
import React from 'react';
import seedrandom from 'seedrandom';
import { Image } from 'react-bootstrap';
import Spin from "../assets/img/icons/Spin.svg";

const Preloader = (props) => {

  const { show, message=null } = props;
  var loadingMessage = null;
  if (!message) {
    loadingMessage = selectMessage();
  } else {
    loadingMessage = message;
  }

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <h5 className={show ? "" : "d-none"}>{loadingMessage}</h5>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={Spin} height={40} />
    </div>
  );
};

function selectMessage() {
  const loadingMessages = [
    "Counting pixels...",
    "Time to grab a snack",
    "Don't blink, you'll miss it...",
    "Loading, loading, loading...",
    "Just keep swimming...",
    "Fetching magic...",
    "It's all in the bytes...",
    "May cause extreme boredom...",
    "Simulating progress...",
    "Waiting for the future...",
    "Catching up with the world...",
    "Chill out, it's loading...",
    "Patience is a virtue...",
    "Fingers crossed for speed...",
    "Sending carrier pigeons...",
    "Time for a quick dance...",
    "All good things take time...",
    "Just hang in there!",
    "Channeling the power of the sun...",
    "Hold on tight!",
    "Behold the spinning circle!",
    "Just one more second...",
    "Just a small eternity...",
    "The suspense is killing us...",
    "Building Rome in a day...",
    "Don't worry, be happy...",
    "Waiting on the hamsters...",
    "Insert clever loading message here...",
    "Channeling our inner sloth...",
    "Not all who wander are lost...",
    "Patience is the key...",
    "Hang tight!",
    "Almost there... not really...",
    "Waiting for the bits to align...",
    "Loading... or just pretending?",
    "Just loading and relaxing...",
    "The internet is a slow place...",
    "Time to grab a cuppa...",
    "Sit back and enjoy the ride...",
    "We're loading, so you don't have to...",
    "The wait is worth it!",
    "Just a few more pixels...",
    "Are we there yet?",
    "Loading... and dreaming of pizza...",
    "Just like that, we're done!",
    "A watched pot never loads...",
    "The internet is hard work...",
    "Just waiting on the pixels...",
    "Searching for the meaning of life...",
    "The bits are on their way...",
    "We're working as fast as we can...",
    "Don't worry, we'll get there...",
    "Time flies when you're waiting...",
    "Loading... and trying to look busy...",
    "Don't forget to breathe...",
    "Just trying to keep you entertained...",
    "A little loading never hurt nobody...",
    "The internet is a vast and mysterious place...",
    "Just a few more packets to go...",
    "Hang tight, the internet gods are at work...",
    "We're almost there, we promise...",
    "Time is a construct, but loading is real...",
    "Just keep refreshing, it'll help... (it wont)",
  ];
  
  const min = new Date().getMinutes();
  const rng = seedrandom(`${min}`);
  const r = Math.floor(rng() * loadingMessages.length);
  return loadingMessages[r];
}

export default Preloader;