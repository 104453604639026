
import React, { useEffect } from "react";
import { Col, Row, Accordion } from 'react-bootstrap';
import { AlertTable } from "../components/Tables";
import { AlertForm } from "../components/Forms";
import { default as RequireAuth } from '../components/RequireAuth';

import { useUser } from "../contexts/userContext";

const Dashboard = () => {
  const { isLoggedIn } = useUser();

  useEffect(() => {
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs={12} className="mb-4">
            <AlertTable />
            <Accordion className="rounded-5 bg-white mt-3">
              <Accordion.Item className="bg-white" eventKey="add-alert">
                <Accordion.Button variant="link" className="bg-white w-100 d-flex justify-content-between">
                  <span className="h5 mb-0 fw-bold">
                    Add Alert
                  </span>
                </Accordion.Button>
                <Accordion.Body>
                <AlertForm />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <RequireAuth />
    );
  }
  
};

export default Dashboard;
