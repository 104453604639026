import React from 'react';
import ReactDOM from 'react-dom/client';
import  { BrowserRouter as Router } from "react-router-dom";

import { MessageBarProvider } from './contexts/MessageBarContext';
import { UserDataProvider } from "./contexts/userContext"
import MessageBar from './components/MessageBar';

import "./scss/volt.scss";

import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Router>
    <MessageBarProvider>
      <UserDataProvider>
        <MessageBar />
        <HomePage />
      </UserDataProvider>
    </MessageBarProvider>
  </Router>
  // </React.StrictMode>
);
