// src/components/RedditOauth.js
import React, { useEffect } from 'react';
import Preloader from './Preloader';

const clientId = process.env.REACT_APP_REDDIT_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDDIT_REDIRECT_URI;
const scope = process.env.REACT_APP_REDDIT_CLIENT_SCOPE;

const RedditOauth = () => {
  const state = Math.random().toString(36).substr(2);
  const authUrl = `https://www.reddit.com/api/v1/authorize?client_id=${clientId}&response_type=code&state=${state}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scope}`;
  
  useEffect(() => {
    window.location.href = authUrl;
  });

  return (
    <Preloader show={true} message="Redirecting to Reddit..." />
  );
};

export default RedditOauth;
