// src/utils/axiosInstance.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMessageBar } from '../contexts/MessageBarContext';


const useAxiosInstance = (token) => {
  const { showMessage } = useMessageBar();
  const Navigate = useNavigate();

  const axiosConfig = {
    baseURL: 'https://api.mrktwtchr.com',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  
  const axiosInstance = axios.create(axiosConfig);
  
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleErrors = (error) => {
    const { response } = error;
  
    if (response) {
      const { status } = response;
      console.log(`response status: ${status}`)
  
      if (status === 401) {
        // console.error('Unauthorized:', response.data.message);
        showMessage(`Unauthorized access.`, 'danger');
        // Navigate('/unauthorized');
      } else if (status === 403) {
        // console.error('Unauthorized:', response.data.message);
        showMessage(`Sorry, can't do that. ${response.data.message}`, 'danger');
        Navigate('/unauthorized');
      } else if (status === 404) {
        console.error('The requested resource was not found:', response.data.message);
        showMessage(`The requested resource was not found`, 'info');
      } else if (status === 500) {
        console.error('An internal server error occurred:', response.data);
        showMessage(`An internal server error occurred. Please try again.`, 'danger');
      } else {
        // Handle other status codes
        console.error(`An error occurred with status code ${status}:`, response.data);
        showMessage(`An unknown error occurred with status code ${status}. Please try again.`, 'danger');
      }
    } else if (error.request) {
      // Handle network errors or timeouts
      console.error('A network error occurred:', error.message);
      showMessage(`An network error occurred: ${error.message}`, 'danger');
    } else {
      // Handle other non-HTTP related errors
      console.error('An unknown error occurred:', error.message);
      showMessage(`An unknown error occurred: ${error.message}`, 'danger');
    }
  
    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use(response => response, handleErrors);

  return axiosInstance;
};

export default useAxiosInstance;

