
export const Routes = {
    // pages
    Presentation: { path: "/" },
    Dashboard: { path: "/dashboard" },
    Settings: { path: "/dashboard/settings" },
    Alert: { path: "/dashboard/alert" },
    NotFound: { path: "/404" },
    Unauthorized: { path: "/unauthorized" },
    Upgrade: { path: "/upgrade" },
    Logout: { path: "/logout" },
    Login: { path: "/login" },
    Discord: { path: "/discord" },
    About: { path: "/about" },
    Contact: { path: "/contact" },
    Forbidden: { path: "/403" },
    ServerError: { path: "/500" },
};