import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import jwt_decode from "jwt-decode";
import merge from "deepmerge";

import { useMessageBar } from "../contexts/MessageBarContext";
import useAxiosInstance from "../utils/axiosInstance";
import { Alert } from '@themesberg/react-bootstrap';


const UserContext = createContext();

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserDataProvider.");
  }
  return context;
};

const UserDataProvider = ({ children }) => {
  const { message, messageType, showMessage } = useMessageBar();
  const cookies = parseCookies();


  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(cookies.token ||  null);
  const [name, setName] = useState(null);
  const [plan, setPlan] = useState(null);
  const [pro, setPro] = useState(null);
  const [maxAlerts, setMaxAlerts] = useState(process.env.REACT_APP_MAX_FREE_ALERTS);
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  const [requestingCheckout, setRequestingCheckout] = useState(false);

  const isLoggedIn = !!token;

  const axios = useAxiosInstance(token);
  const navigate = useNavigate();

  // Initially, try getting the data from localStorage. If it's not there, use empty object / null.
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')) || {});
  const [alertData, setAlertData] = useState(JSON.parse(localStorage.getItem('alertData')) || null);


  const [fetchingAlerts, setFetchingAlerts] = useState(false);

  const updateUserData = (newUserData) => {
    if (!isEqual(userData, newUserData)) {
      setUserData(userData => {
        const mergedUserData = merge(userData, newUserData);
        return mergedUserData;
      });
    }

  };

  const updateAlertData = (newAlertData) => {
    if (!isEqual(alertData, newAlertData)) {
      setAlertData(newAlertData);
    }
  };

  const login = async (newToken) => {
    try {
      setToken(newToken);
      setCookie(null, 'token', newToken, {
        maxAge: 30 * 24 * 60 * 60, 
        path: '/',
        secure: true
      });
      localStorage.setItem('token', newToken);
      fetchAlerts();
    } catch (err) {
      alert(err.message);
    }
  };

  const logout = async () => {
    setToken(null);
    setUserLoggedOut(true);
    destroyCookie(null, 'token', { path: '/' });
    localStorage.removeItem('token');
    navigate("/logout");
  };
  
  const unauth = async () => {
    setToken(null);
    setUserLoggedOut(false);
    destroyCookie(null, 'token', { path: '/' });
    navigate("/unauthorized");
  };

  const fetchUserData = useCallback( async () => {
    if (token) {
      setIsLoading(true);
      try {
        // console.log('fetching user data')
        const response = await axios.get("/user", {
          headers: {
            "Authorization" : `Bearer ${token}`
          }
         });
        updateUserData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        throw error;
      }
    }
  },[token]);

  const updateUser = useCallback( async (data) => {
    if (token) {
      try {
        // console.log('updating user data')
        const response = await axios.post("/user/update", data, {
          headers: {
            "Authorization" : `Bearer ${token}`
          }
        });
        updateUserData(response.data.data);
        console.log('updated user')
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        throw error;
      }
    }
  },[token]);

  const fetchAlerts = useCallback( async () => {
    if (token) {
      setFetchingAlerts(true);
      axios.get(`/alert/list`, {
        headers: {
           "Authorization" : `Bearer ${token}`
         }
       }).then((response) => {
        updateAlertData(response.data.data);
        setFetchingAlerts(false);
      }).catch((error) => {
        // console.error('Failed to fetch alerts:', JSON.stringify(error));
        showMessage(`Failed to fetch alerts. Please try again`, 'danger');
        setFetchingAlerts(false);
      });
    }
  },[token]);

  const refreshToken = useCallback( async (upgrade=false) => {
    const url = upgrade ? "/auth/token/upgrade" : "/auth/token"
    if (token) {
      axios.get(url, {
        headers: {
           "Authorization" : `Bearer ${token}`
         }
      }).then((response) => {
        // console.log(JSON.stringify(response));
        setToken(response.data.token);
        // console.log('refreshed token', response.data.token);
      }).catch((error) => {
        console.error('Failed to refresh token:', JSON.stringify(error));
        showMessage('Failed to fetch alerts. Please try again.');
      });
    }
  },[]);

  const getCheckoutSession = async () => {
    setRequestingCheckout(true);
    const response = await axios.get('/user/checkout', {
      headers: {
         "Authorization" : `Bearer ${token}`
       }
     });
    // console.log(JSON.stringify(response.data.session_url));
    setTimeout(() => {
      setRequestingCheckout(false);
    }, 5000);
    window.location.href = response.data.session_url;
    
  }

  useEffect(() => {
    if (token) {
      try {
        setToken(token);
        setCookie(null, 'token', token, {
          maxAge: 7 * 24 * 60 * 60, // 30 days
          path: '/',
          secure: true
        });
        const decodedJwt = jwt_decode(token);
        setName(decodedJwt.sub);
        // console.log(`setting user plan: ${decodedJwt.plan}`);
        setPlan(decodedJwt.plan);
        setPro(decodedJwt.plan === "pro");
        setMaxAlerts(decodedJwt.plan === "pro" ? process.env.REACT_APP_MAX_PRO_ALERTS: process.env.REACT_APP_MAX_FREE_ALERTS);
        fetchUserData();
      } catch (e) {
        console.error('Invalid token!');
      }
    }
  }, [token]);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  useEffect(() => {
    localStorage.setItem('token', token);
  }, [token]);
  
  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);
  
  useEffect(() => {
    localStorage.setItem('alertData', JSON.stringify(alertData));
  }, [alertData]);


  const value = {
    isLoading,
    isLoggedIn,
    token,
    name,
    plan,
    pro,
    maxAlerts,
    userData,
    alertData,
    login,
    logout,
    unauth,
    setToken,
    updateUserData,
    setUserData,
    setAlertData,
    updateAlertData,
    fetchUserData,
    fetchAlerts,
    refreshToken,
    updateUser,
    getCheckoutSession,
    requestingCheckout
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export { UserDataProvider, useUser };
