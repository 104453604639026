

const methods = [
  { id: 1, displayName: "Reddit Message", label: "reddit", target: false, free: true },
  { id: 2, displayName: "Discord", label: "discord", target: true, disabledTarget: true, free: false, hasInfo: true},
  { id: 3, displayName: "Email", label: "email", target: true, free: false },
  { id: 4, displayName: "Slack Webhook", label: "slack", target: true, free: false },
  { id: 5, displayName: "Webhook", label: "webhook", target: true, free: false },
];

export default methods;