
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Nav, Modal, Navbar, Dropdown, Container } from 'react-bootstrap';
import { faRocket } from '@fortawesome/free-solid-svg-icons';

import { useUser } from "../contexts/userContext";
import { useMessageBar } from "../contexts/MessageBarContext";

import useAxiosInstance from '../utils/axiosInstance';
import UserAvatar from "../components/UserAvatar";

const maxAlerts = 10


const NavBar = (props) => {
  const { token, logout, isLoggedIn, name, plan, pro } = useUser();
  const { showMessage } = useMessageBar();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const handleClose = () => setShowUpgradeModal(false);
  const axios = useAxiosInstance(token);
  const navigate = useNavigate();

  useEffect(() => {
  }, [isLoggedIn]);

  const handleLogout = () => {
    logout();
  };

  const getCheckoutSession = async () => {
    const response = await axios.get('/user/checkout', {
      headers: {
         "Authorization" : `Bearer ${token}`
       }
     });
    // console.log(JSON.stringify(response.data.session_url));
    window.location.href = response.data.session_url;
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 d-none d-md-block">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          { isLoggedIn ?
            <div>
              { pro && <Badge bg="tertiary" className="plan ms-2 px-2 py-1">
                  {plan.toUpperCase()}
                </Badge>
              }
            </div> : <div className="py-4 ms-2"></div>
          }
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 mb-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body me-2 text-dark align-items-center d-none d-lg-block">
                    { isLoggedIn && <span className="mb-0 font-small fw-bold">{name}</span> }
                  </div>
                  <div className="user-avatar">
                  { isLoggedIn && <UserAvatar name={name} /> }
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                { isLoggedIn ? 
                  <Dropdown.Item as={Nav.Item} className="fw-bold" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Sign Out
                  </Dropdown.Item> :
                  <Dropdown.Item as={Nav.Item} className="fw-bold" onClick={() => navigate('/login')}>
                    <FontAwesomeIcon icon={faSignInAlt} className="text-success me-2" /> Sign In
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      <Modal as={Modal.Dialog} centered show={showUpgradeModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Upgrade</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="product">
            <div className="description">
              <h3>MRKTWTCHR PRO</h3>
              <h5>$10.00 / year</h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray" onClick={handleClose}>No thanks</Button>
          <Button variant="secondary ms-auto" onClick={getCheckoutSession}>Lets do it!</Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};

export default NavBar;