
const subreddits = [
    { id: 1, displayName: "r/mechmarket" ,label: "mechmarket", placeholder: "ex: \"GMK\", \"artisan\"", filters: ["country", "state", "intent"] },
    { id: 2, displayName: "r/photomarket" ,label: "photomarket", placeholder: "ex: \"Cannon\", \"35mm\"", filters: ["country", "state", "intent"] },
    { id: 3, displayName: "r/knife_swap" ,label: "knife_swap", placeholder: "ex: \"Spyderco\", \"CRK\"", filters: ["intent"] },
    { id: 4, displayName: "r/watchexchange" ,label: "watchexchange", placeholder: "ex: \"Rolex\", \"Apple\"", filters: ["intent"] },
    { id: 5, displayName: "r/gamesale" ,label: "gamesale", placeholder: "ex: \"Fallout\", \"Mario Cart\"", filters: ["country", "state", "intent"] },
    { id: 6, displayName: "r/hardwareswap" ,label: "hardwareswap", placeholder: "ex: \"4090\", \"SSD\"", filters: ["country", "state", "intent"] },
    { id: 7, displayName: "r/sneakermarket" ,label: "sneakermarket", placeholder: "ex: \"New balance 574\", \"Air Max\"", filters: ["intent"] },
    { id: 8, displayName: "r/avexchange" ,label: "avexchange", placeholder: "ex: \"headphones\"", filters: ["country", "state", "intent"] },
    { id: 9, displayName: "r/homelabsales" ,label: "homelabsales", placeholder: "ex: \"Xeon\", \"PoE\"", filters: ["country", "state", "intent"] }
];

export default subreddits;