
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';
import SelectTagline from "../data/taglines";

import { Routes } from "../routes";


const Footer = (props) => {
  const currentYear = moment().get("year");
  // const taglineId = Math.floor( 1 + Math.random() * taglines.length )

  return (
    <div className="mt-auto">
      <footer className="footer section pt-5 pb-3">
        <Row>
          <Col xs={12} md={6} className="mb-2 mb-lg-0">
            <p className="mb-0 text-center text-md-left">
            <small><strong>MRKTWTCHR</strong></small> &copy; {`${currentYear}`}
            </p>
            <p className="mb-0 text-center text-md-left" ><small><SelectTagline/></small></p>
          </Col>
          <Col xs={12} md={6} className="align-items-end align-bottom">
            <ul className="list-inline list-group-flush list-group-borderless text-center text-md-right mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.About.path}>About</Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href={Routes.Contact.path}>Contact</Card.Link>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default Footer;