
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt, faTimes, faCheck, faEyeSlash, faCameraRetro, faKeyboard, faRefresh, faHeadphonesAlt, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsWatch, BsGpuCard, BsArrowRepeat } from "react-icons/bs";
import { GiSwissArmyKnife, GiConverseShoe } from "react-icons/gi";
import { CgGames } from "react-icons/cg";
import moment from "moment-timezone";

import { useUser } from "../contexts/userContext";
import useAxiosInstance from "../utils/axiosInstance";
import { useMessageBar } from "../contexts/MessageBarContext";


const getIconFromName = (subreddit, color="gray") => {
  const size = "1.35em";
  switch (subreddit) {
    case 'mechmarket':
      return <FontAwesomeIcon className="me-2 my-auto" icon={faKeyboard} color={color}/>;
    case 'photomarket':
      return <FontAwesomeIcon className="me-2 my-auto" icon={faCameraRetro} color={color}/>;
    case 'knife_swap':
      return <GiSwissArmyKnife className="me-2 my-auto" size={size} color={color} />;
    case 'watchexchange':
      return <BsWatch className="me-2 my-auto" size={size} color={color} />;
    case 'gamesale':
      return <CgGames className="me-2 my-auto" size={size} color={color} />;
    case 'hardwareswap':
      return <BsGpuCard className="me-2 my-auto" size={size} color={color} />;
    case 'sneakermarket':
      return <GiConverseShoe className="me-2 my-auto" size={size} color={color} />;
    case 'avexchange':
      return <FontAwesomeIcon className="me-2 my-auto" icon={faHeadphonesAlt} color={color}/>;
    case 'homelabsales':
      return <FontAwesomeIcon className="me-2 my-auto" icon={faNetworkWired} color={color}/>;
    default:
      return <></>
  }
}

export const AlertTable = () => {
  const { token, alertData, fetchAlerts, setAlertData, updateAlertData } = useUser();
  const { showMessage } = useMessageBar();
  const [ fetching, setFetching ] = useState(false);
  const [ confirming, setConfirming ] = useState(false);
  const [ confirmingId, setConfirmingId ] = useState(null);
  const [ modalMatches, setModalMatches ] = useState([]);

  const axiosInstance = useAxiosInstance(token);
  const [showMatchesModal, setShowMatchesModal] = useState(false);
  
  const handleClose = () => setShowMatchesModal(false);
  const openMatchesModal = (matches) => {
    setModalMatches(matches)
    setShowMatchesModal(true);
  };

  const refreshAlerts = async () => {
    try {
      setFetching(true);
      await fetchAlerts();
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    } catch {
      setFetching(false);
    }
  };
  
  useEffect(() => {
    if (!alertData) {
      fetchAlerts();
    }
  }, []);
  
  useEffect(() => {
  }, [alertData]);

  const toggleAlert = async (id, enabled) => {
    try {
      const response = await axiosInstance.get(`/alert/${id}/${!!+enabled ? "disable" : "enable"}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
         }
       });
      if (response.status >= 200 && response.status < 300) {
        const updatedAlertData = Array.from(alertData).map(w => {
          if (w.id === id) {
            return { ...w, enabled: !!+enabled ? "0" : "1" };
          }
          return w;
        });
        setAlertData(updatedAlertData);
        // showMessage(`Successfully ${!!+enabled ? "disabled" : "enabled"} alert`, 'success');
      }
    } catch (error) {
      console.error(`Failed to toggle alert`);
      showMessage(`Error ${!!+enabled ? "disabling" : "enabling"} alert`, 'danger');
      throw error;
    }
  }

  const deleteAlert = async (id) => {
    try {
      const response = await axiosInstance.get(`/alert/${id}/delete`, {
        headers: {
           "Authorization" : `Bearer ${token}`
         }
       });
      //  console.log(`delete response: ${JSON.stringify(response.data)}`)
      if (response.status >= 200 && response.status < 300) {

        const updatedAlertData = Array.from(alertData).filter(w => {
          return w.id !== id;
        });
        setAlertData(updatedAlertData);
        // showMessage(`Successfully deleted alert`, 'success');
      }
    } catch (error) {
      console.error(`Failed to delete alert`, error);
      showMessage(`Error deleting alert`, 'danger');
    }
  }

  const filtersToString = (filters) => {
    if (filters.country) {
      if (filters.state) {
        return `${filters.country}-${filters.state}`
      } else {
        return `${filters.country}`
      }
    }
    return ""
  };

  const TableRow = (props) => {
    const { id, created_at, subreddit, terms, intent, filters, matches, enabled, sourceIconColor } = props;
    // console.log(`enabled=${enabled}`, typeof(enabled))
    const enabledBool = enabled === "1"

    return (
      <tr>
        {/* <td className="border-0 d-none d-md-table-cell">{created_at ? moment(created_at).format("MM/DD/YYYY") : "--"}</td> */}
        <td className="border-0 d-flex">{getIconFromName(subreddit, sourceIconColor)}<span className="d-none d-md-flex">r/<strong>{subreddit}</strong></span></td>
        <td className="border-0">{terms}</td>
        <td className="border-0">{intent}</td>
        <td className="border-0">{filtersToString(filters)}</td>
        <td className="border-0">{ matches.length ? <Button className="m-0 p-0" style={{"text-decoration": "underline"}} variant="link" onClick={() => openMatchesModal(matches)}><small>{matches.length}</small></Button> : matches.length }</td>
        <td className="border-0">
          <Button variant="link" className="ms-1 p-0">
            <FontAwesomeIcon icon={enabledBool ? faEye : faEyeSlash}  className={enabledBool ? "me-2 text-success" : "me-2 text-danger"} onClick={ () => { toggleAlert(id, enabled) }}/>
          </Button>
        </td>
        <td id={`rem-${id}`} className="border-0 text-right"><div style={{ width: "5px" }} className="d-flex justify-content-end">
          { confirming && confirmingId === id ? <>
            <Button variant="link" className="ms-1 p-0">
              <FontAwesomeIcon icon={faCheck} className="me-2 delyes" onClick={ () => { deleteAlert(id) }}/>
            </Button>
            <Button variant="link" className="ms-1 p-0">
              <FontAwesomeIcon icon={faTimes} className="me-2 delno" onClick={ () => { setConfirming(false); setConfirmingId(id) }}/>
            </Button></>
            :
            <Button variant="link" className="ms-1 p-0">
              <FontAwesomeIcon icon={faTrashAlt} className="me-2 delwtchr can" onClick={ () => { setConfirming(true); setConfirmingId(id) }}/>
            </Button>
          }</div>
        </td>
        
      </tr>
    );
  };

  return (
    <>
    <Card border="light" className="shadow-sm mt-3">
      <Card.Body className="pb-2">
        <h5 className="mb-4 fw-bold"><span className="h5">Alerts</span></h5>
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              {/* <th className="border-0 d-none d-md-table-cell">Created</th> */}
              <th className="border-0 d-flex">Sub<span className="d-none d-md-flex">reddit</span></th>
              <th className="border-0">Terms</th>
              <th className="border-0">Intent</th>
              <th className="border-0">Filters</th>
              <th className="border-0">Matches</th>
              <th className="border-0">Enabled</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            { (alertData && alertData?.length) ? 
              alertData.map(w => <TableRow key={`alert-${w.id}`} {...w} />)
            :
              ( fetching ?
                <tr >
                  <td className="border-0" colSpan={6}>Fetching Alerts...</td>
                </tr>
              :
                <tr >
                  <td className="border-0" colSpan={6}>No Alerts yet!</td>
                </tr>
              )
            }
          </tbody>
        </Table>
        <div className="d-flex justify-content-end">
          <Button variant="light" className="m-2 p-1 alert-refresh-button">
            <div className="py-1 px-2">
              <div className={`alert-refresh-icon${fetching ? '-spin' : ''}`}>
                <FontAwesomeIcon icon={faRefresh} onClick={() => {refreshAlerts()}} />
              </div>
            </div>
          </Button>
        </div>
      </Card.Body>
    </Card>
    <Modal as={Modal.Dialog} centered show={showMatchesModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Matches</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {modalMatches.map((match, index) => (
            <p><a href={`https://redd.it/${match}`} target="_blank" key={index} rel="noreferrer">https://redd.it/{match}</a></p>
          ))}
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};