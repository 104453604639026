
import seedrandom from 'seedrandom';

const SelectTagline = () => {
  const hour = new Date().getHours();
  const rng = seedrandom(hour);
  const i = Math.floor(rng() * taglines.length);
  return taglines[i];
}

export const taglines = [
  "Your subreddit stalker for sick scores",
  "The rad radar for rare reddit reveals",
  "We spy so you can buy",
  "The deal whisperer for your next haul-iday",
  "Turning your wishlist into a hitlist, one alert at a time",
  "Your sneaky sidekick for subreddit steals",
  "Say cheerio to FOMO",
  "The coolest treasure hunter in cyberspace",
  "Your personal post patrol for prime pickings",
  "A alert worth a thousand smiles",
  "Swoop, swap, and smile",
  "Say goodbye to FOMO",
  "FOMO NO MO'",
  "Deals in sight, day or night",
  "Spot the hot, skip the not",
  "Swap, shop, and giggle",
  "Deal-hunting? We've got the nose for it",
  "We keep an eye out, you keep a grin on",
  "Deal spotting: less snooze, more schmooze",
  "Finders keepers, we're your peepers",
  "Hunting for steals, without the squeals",
  "Vigilance made easy",
  "Relax, we're on the lookout",
  "Eyes on the prize, hands off the keyboard",
];

export default SelectTagline;