
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import { useUser } from '../contexts/userContext';
import LogoutImage from "../assets/img/illustrations/logout.svg";


const Unauthorized = () => {
  const { unauth } = useUser();

  useEffect(() => {
    unauth();
  }, []);

  return (
    <main>
      <section className="d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div className="mt-2 mb-6">
                <Card.Link as={Link} to={Routes.Login.path}>
                  <Image src={LogoutImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-4 mb-4">
                  <span className="fw-bolder">unauthorized</span>
                  {/* <span className="fw-bolder">unauthorized</span> */}
                </h1>
                <h4>Please <Button as={Link} to={Routes.Login.path} variant="secondary" className="animate-hover">Sign in <FontAwesomeIcon icon={faSignInAlt} className="d-none d-sm-inline animate-right-3 ms-1" /></Button> to view your <span className="fw-bolder">Dashboard</span></h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Unauthorized;