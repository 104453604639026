
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Alert, Col, Row, Card, Form, Button, Tooltip, OverlayTrigger, Badge, Accordion } from 'react-bootstrap';

import { useUser } from "../contexts/userContext";
import { useMessageBar } from "../contexts/MessageBarContext";
import {Routes as AppRoutes} from "../routes" ;
import useAxiosInstance from "../utils/axiosInstance";

import subreddits from '../data/subreddits';
import countries from '../data/countries';
import states from '../data/states';
import methods from '../data/alertMethods';
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

const discordInviteUrl = process.env.REACT_APP_DISCORD_INVITE_URL;

const renderProTooltip = (message) => (
  <Tooltip id="pro-tooltip" >
    { message ? message : "This is a PRO feature" }
  </Tooltip>
);

export const GeneralInfoForm = () => {
  const { token, userData, updateUserData, pro } = useUser();
  const { showMessage } = useMessageBar();
  const axios = useAxiosInstance(token);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const [email, setEmail] = useState(userData?.email || "");
  // const [phone, setPhone] = useState(userData?.phone || "");

  useEffect(() => {
    if (userData) {
      setFirstname(userData.firstname || "");
      setLastname(userData.lastname || "");
    }
  }, [userData]);

  const getPortalSession = async () => {
    const response = await axios.get('/user/portal', {
      headers: {
        "Authorization" : `Bearer ${token}`
       }
    });
    // console.log(JSON.stringify(response.data.session_url));
    window.location.href = response.data.session_url;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = {
      firstname,
      lastname,
      // email,
      // phone
    };

    try {
      const response = await axios.post(`/user/update`, formData, {
        headers: {
           "Authorization" : `Bearer ${token}`
         }
       });
      if (response.status >= 200 && response.status < 300) {
        // console.log(`Successfully updated user information`);
        updateUserData(formData);
        showMessage(`User information updated`);
      } else {
        console.error(`Failed to update user information`);
        showMessage(`Failed to update user information. Please try again.`, 'danger');
      }
    } catch (error) {
      console.error(`Failed to update user information`);
      showMessage(`Failed to update user information. Please try again.`, 'danger');
    }
    // console.log(formData);
  };
  

  return (
    <Card border="light" className="bg-white shadow-sm mb-2">
      <Card.Body>
        <h5 className="mb-4">Account</h5>
        <p>This information is entirely optional</p>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6} xl={4} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder={ firstname || "First name"}
                />
              </Form.Group>
            </Col>
            <Col xs={6} xl={4} className="mb-2">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder={ lastname || "Last name"}
                />
              </Form.Group>
            </Col>
            {/* <Col md={4} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={ email || ""}
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            
            {/* <Col md={6} className="mb-3">
              <Form.Group id="phone">
              <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={ phone || "+1 123 867 5309"}
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col xs={3} sm={2} xl={1} className="mt-2">
              <Button variant="primary" className="px-3" type="submit">Save</Button>
            </Col>
            <Col xs={9} sm={6}  className="mt-2">
            { pro && 
              <Button variant="secondary" className="px-4" onClick={getPortalSession}>
                Manage Subscription
              </Button>
            }
            </Col>
          </Row>
        </Form>
        
      </Card.Body>
    </Card>
  );
};

export const AlertForm = () => {
  const { token, alertData, fetchAlerts, pro, maxAlerts, getCheckoutSession } = useUser();
  const { showMessage } = useMessageBar();
  const axios = useAxiosInstance(token);
  const navigate = useNavigate();

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [subreddit, setSubreddit] = useState(subreddits[0]);
  const [intent, setIntent] = useState(true);
  const [country, setSelectedCountry] = useState(null);
  const [state, setSelectedState] = useState(null);
  const [terms, setTerms] = useState("");
  const [searchBody, setSearchBody] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      subreddit: subreddit.label,
      terms,
      intent: intent ? "buying" : "selling",
      country: country,
      state: state,
      search_body: searchBody
    };

    if (alertData?.length < maxAlerts) {
      try {
        const response = await axios.post(`/alert/add`, formData, {
          headers: {
             "Authorization" : `Bearer ${token}`
           }
         });
        if (response.status >= 200 && response.status < 300) {
          setFiltersVisible(false);
          setSubreddit(subreddits[0]);
          setIntent(true);
          setSelectedCountry(null);
          setSelectedState(null);
          setSearchBody(false);
          setTerms("");
          navigate("/dashboard");
          showMessage(`Alert added!`);
        }
        fetchAlerts();
      } catch (error) {
        // console.error(`Failed to create alert`, error);
        showMessage(`Failed to create alert`);
      }
      
    } else {
      showMessage(`Alert limit reached. Max alerts is ${maxAlerts}`, 'info');
      // console.error(`Alert limit reached. Max alerts is ${maxAlerts}`)
    }
    
  };

  const handleSubredditChange = (e) => {
    const selectedsubreddit = subreddits.find(m => m.id === parseInt(e.target.value));
    setSubreddit(selectedsubreddit);
  };

  const handleTermsChange = (e) => {
    setTerms(e.target.value)
  };


  return (
    
      <>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={5} xl={2} className="mb-3">
              <Form.Group id="subreddit">
                <Form.Label>Subreddit</Form.Label>
                <Form.Select required id="subreddit" onChange={handleSubredditChange} >
                  {subreddits.map(m => <option key={m.id} value={m.id}>{m.displayName}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={7} xl={4}className="mb-3">
              <Form.Group id="terms">
                <Form.Label>Search Terms</Form.Label>
                <Form.Control required type="text" maxLength={32} minLength={3} placeholder={subreddit.placeholder} value={terms} onChange={handleTermsChange}/>
              </Form.Group>
            </Col>
            {subreddit.filters.includes("intent") && (
              <Col xs={4}  xl={2} className="mb-3">
                <Form.Label>Are you...</Form.Label>
                <Form.Check
                  type="switch"
                  id="intentSwitch"
                  label={intent ? "Buying" : "Selling"}
                  checked={intent}
                  onChange={() => {setIntent(!intent) }}
                />
              </Col>
            )}
            <Col xs={4} xl={2} className="">
              <Form.Label>Search Body <OverlayTrigger
                  key="body-q-overlay"
                  trigger={["focus", "hover", "click"]}
                  placement={"top"}
                  delay={{ hide: 500 }}
                  overlay={
                    <Tooltip id="search-body-tooltip">
                      May result in a lot of false positives!
                    </Tooltip>}>
                  <span>
                    <Button disabled variant="link" className="ms-1 p-0">
                      <FontAwesomeIcon className="m-0 p-0" icon={faQuestionCircle} />
                    </Button>
                  </span>
                </OverlayTrigger></Form.Label>
                <Form.Check 
                  disabled={!pro}
                  type="switch"
                  id="searchBodySwitch"
                  checked={searchBody}
                  onChange={(e) => { setSearchBody(e.target.checked) }}
                />
              </Col>
            <Col xs={4} xl={2} className="mb-3">
              <Form.Group id="showFilters">
                <Form.Label>Advanced 
                  { pro ? <OverlayTrigger
                    key="filter-q-overlay"
                    trigger={["focus", "hover", "click"]}
                    placement={"top"}
                    delay={{ hide: 500 }}
                    overlay={
                      <Tooltip id="filter-tooltip">
                        Heads up! These options are experimental and can drastically affect your matches; use judiciously.
                      </Tooltip>}>
                    <span>
                      <Button disabled variant="link" className="ms-1 p-0">
                        <FontAwesomeIcon className="m-0 p-0" icon={faQuestionCircle} />
                      </Button>
                    </span>
                  </OverlayTrigger> :
                  <OverlayTrigger
                  key="filter-overlay"
                  placement={"top"}
                  delay={{ hide: 500 }}
                  overlay={renderProTooltip("Upgrade to use Filters")}
                ><Badge pill className="badge-md ms-2">PRO</Badge></OverlayTrigger>
                  }
                </Form.Label>
                <Col sm={{ span: 1, offset: 0 }} md={{ span: 2, offset: 0 }}>
                  <div className="d-inline-block">
                    <Form.Check 
                      disabled={!pro}
                      type="switch"
                      id="filtersSwitch"
                      checked={filtersVisible}
                      onChange={() => { setFiltersVisible(!filtersVisible) }}
                    />
                  </div>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {filtersVisible && (
            <Row className="my-1">
              {subreddit.filters.includes("country") && (
              <Col md={3} className="mb-2">
                <Form.Group id="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Select disabled={!pro} id="country" defaultValue="0" onChange={(e) => {setSelectedCountry(e.target.value)}}>
                    {countries.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
              )}
              {country === "US" && subreddit.filters.includes("state") && (
              <Col md={3} className="">
                <Form.Label>State</Form.Label>
                  <Form.Select disabled={!pro} id="state" defaultValue="0" onChange={(e) => {setSelectedState(e.target.value)}}>
                    {states.map(s => <option key={s.code} value={s.code}>{s.name}</option>)}
                  </Form.Select>
              </Col>
              )}
            </Row>
          )}          
          <Row>
            <Col xs={3} sm={2} lg={1} className="mt-2">
              { alertData?.length < maxAlerts ? 
                <Button variant="primary" className="px-3" type="submit">Add</Button>
              :
              <OverlayTrigger
                key="add-alert-overlay"
                trigger={["focus", "hover", "click"]}
                placement={"right"}
                delay={{ hide: 500 }}
                overlay={
                  <Tooltip id="alert-tooltip">
                    { pro ? `Alert limit is ${maxAlerts}!` : `Free alert limit is ${maxAlerts}. Upgrade for more!`}
                  </Tooltip>}>
                <span><Button disabled={true} variant="primary" className="px-3" type="submit">Add</Button></span>
              </OverlayTrigger>
              }
            </Col>
            <Col xs={8} className="mt-2">
            { !pro &&
              <Button as={Link} onClick={getCheckoutSession} variant="secondary" className="px-4"><FontAwesomeIcon icon={faRocket}/> Upgrade</Button>
            }
            </Col>
          </Row>
          <Row className="mt-3">
          <Col className="">
            {/* <Alert.Link variant={"link"} href={AppRoutes.Discord.path}>a link</Alert.Link> */}
            <Accordion flush defaultActiveKey={null}>
              <AccordionItem>
                <AccordionHeader className="color-primary">Search Tips</AccordionHeader>
                <AccordionBody>
                  <h6>Search has changed in several ways from the old mrktwtchr!</h6>
                  <h4>Terms</h4>
                  <p>Enter one or more terms to search for. Wildcards are now the only supported operator. For example, "key*" will match "key", "keyboard", "keycaps", etc.</p>
                  <h4>Intent</h4>
                  <p>Select "Buying" or "Selling". If <em>Buying</em> we will search the "have" section of the title, and <em>Selling</em> we will search the "want". This reduces false positives. Also useful if you have items to sell. If a buyer doesn't search or just misses your sale post, you can catch their "<em>want</em>" post. If the subreddit title requirements include specifying an intent like "[WTS]", or if it can be inferred by the <em>have</em>/<em>want</em> sections this will be more accurate. If it cant be determined, "Selling" is assumed.</p>
                  <h4>Search Body</h4>
                  <p>Search Body will do just that, search the entire post body. This may result in a lot of false positives, but will catch the posts where the items are not all specified in the title.</p>
                  <h4>Advanced <span className="plan-sm align-middle"><Badge pill className="plan-sm badge-sm ms-2 align-middle">PRO</Badge></span></h4>
                  <p>In Advanced search, you can specify a country filter. This will exclude any posts that do not match the selected country. It will also exclude any posts that don't specify a country, or are not in the standard 2 or 3 letter ISO-3166 format. Ex. "[USofA]" in the title will not match.</p>
                  <h4>Questions?</h4>
                  <p>Head over to the <a target="_blank" rel="noreferrer" href="https://discord.gg/edzhTeQk8A"><strong>mrktwtchr</strong> Discord</a> #support channel!</p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>              
          </Col>
          </Row>
        </Form>
      
        </>
  );
};

export const ContactForm = () => {
  const { userData } = useUser();
  const { showMessage } = useMessageBar();
  const axios = useAxiosInstance();

  const [email, setEmail] = useState(userData?.email || "");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submittied, setSubmitted] = useState(false);

  useEffect(() => {
  }, [userData]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    
    const formData = {
      email,
      message
    };

    try {
      const response = await axios.post('/contact', formData);
      if (response.status >= 200 && response.status < 300) {
        showMessage('Message submitted successfully!');
        setSubmitted(true);
        setSubmitting(false);
      } else {
        showMessage('Failed to submit contact form. Please try again!', 'danger');
      }
    } catch (error) {
      console.error(`Failed to update user information`);
      throw error;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={5} className="mb-3">
          <Form.Group id="firstName">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              disabled={submitting}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={ email || ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="mb-3">
          <Form.Group id="phone">
          <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              disabled={submitting}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder=""
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex d-inline mt-3 pb-3 ">
        <Button disabled={submitting} className="py-" variant="primary" type="submit">Send</Button>
        { submittied && 
          <p className="m-2 ms-4">Message sent! We'll get back to you shortly!</p>
        }
      </div>
    </Form>
  );
};

export const NotificationForm = () => {
  const { token, userData, updateUser, pro, isLoading } = useUser();
  const { showMessage } = useMessageBar();
  const axios = useAxiosInstance(token);
  // const navigate = useNavigate();

  // const initialMethod = methods.find(m => m.label === userData?.notification_method) || methods.find(m => m.id === 1);
  const blankTargets = {
    discord: userData?.discord_target || "",
    email: userData?.email_target || "",
    slack: userData?.slack_target || "",
    webhook: userData?.webhook_target || ""
  };

  const [initialMethod, setInitialMethod] = useState(methods.find(m => m.label === userData?.notification_method) || methods.find(m => m.id === 1) || null);
  const [initialTargets, setInitialTargets] = useState(blankTargets);
  const [method, setMethod] = useState(initialMethod);
  // const [targets, setTargets] = useState({});
  const [targetError, setTargetError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [tested, setTested] = useState(false);
  const [testOk, setTestOk] = useState(false);
  const [validInput, setValidInput] = useState(true);
  // const [message, setMessage] = useState('');
  const [showInfo, setShowInfo] = useState(true);
  // const [targetText, setTargetText] = useState(null);

  const [emailTarget, setEmailTarget] = useState(userData?.email_target || "");
  const [slackTarget, setSlackTarget] = useState(userData?.slack_target || "");
  // const [redditTarget, setRedditTarget] = useState(userData?.reddit_target || "");
  const [webhookTarget, setWebhookTarget] = useState(userData?.webhook_target || "");
  const [discordTarget, setDiscordTarget] = useState(userData?.discord_target || "");

  useEffect(() => {
    if (userData) {
      const userMethod = methods.find(m => m.label === userData?.notification_method);
      if (userMethod) {
        setMethod(userMethod);
        setInitialMethod(userMethod);
      }
  
      const userTargets = {
        discord: userData?.discord_target,
        email: userData?.email_target,
        slack: userData?.slack_target,
        webhook: userData?.webhook_target
      };
      setInitialTargets(userTargets);
  
      setEmailTarget(userData?.email_target || "");
      setSlackTarget(userData?.slack_target || "");
      setWebhookTarget(userData?.webhook_target || "");
      setDiscordTarget(userData?.discord_target || "");
    }
  }, [userData]);

  useEffect(() => {
    if(submitted) {
      showMessage('Method updated!');
    }
    if(tested) {
      showMessage('Test submitted!');
    }
  }, [submitted, tested]);

  const validateTarget = (type, target) => {
    let re = null;
    let error = ""
    if (target.length) {
      if (type === 'email') {
        re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        error = "Invalid email address"
      } else if (type === 'slack') {
        re = /^(http|https):\/\/[^ "]+$/;
        error = "Invalid Slack webhook URL"
      } else if (type === 'webhook') {
        re = /^(http|https):\/\/[^ "]+$/;
        error = "Invalid URL"
      } else if (type === 'discord') {
        re = /^\d+$/;;
        error = "Invalid user ID"
      }
      if (re.test(target)) {
        setTargetError("");
        setValidInput(true);
      } else {
        setTargetError(error);
        setValidInput(false);
      }
    } else {
      setTargetError(error);
      setValidInput(false);
    }
  };

  const handleMethodChange = (event) => {
    const methodId = Number(event.target.value);
    const selectedMethod = methods.find(m => m.id === methodId);

    if (selectedMethod.label === "discord") {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }

    setTargetError("");
    setValidInput(true);

    setSubmitted(false);
    setTested(false);
    setMethod(selectedMethod);
  };

  const handleTargetChange = (event) => {
    setSubmitted(false);
    setTested(false);
    const newTarget = event.target.value;
    if (method.label === 'email') {
      setEmailTarget(newTarget);
      userData.email_target = newTarget;
    } else if (method.label === 'slack') {
      setSlackTarget(newTarget);
      userData.slack_target = newTarget;
    } else if (method.label === 'discord') {
      setDiscordTarget(newTarget);
      userData.discord_target = newTarget;
    } else if (method.label === 'webhook') {
      setWebhookTarget(newTarget);
      userData.webhook_target = newTarget;
    }
  };

  const handleTargetBlur = (target) => {
    // console.log('label: ', method.label, 'target:', target)
    validateTarget(method.label, target)
  };

  const handleTest = () => {
    const formData = {
      method: method.label,
      target: userData[`${method.label}_target`]
    }
    axios.post('/user/testnotify', formData, {
      headers: {
        "Authorization" : `Bearer ${token}`
       }
    }).then((response) => {
      setTested(true);
      setTimeout(() => {
        setTested(false);
      }, 10000);
      if (response.status >= 200 && response.status < 300) {
        setTestOk(true);
      } else {
        setTestOk(false);
      }
    }).catch((e) => {
      setTestOk(false);
      console.log('Error submitting test notification');
    })
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    await updateUser({
      notification_method: method.label,
      email_target: emailTarget,
      slack_target: slackTarget,
      webhook_target: webhookTarget,
      discord_target: discordTarget
    });
    setSubmitted(true);
    setSubmitting(false);
  };

  const methodChanged = method && initialMethod && method.label !== initialMethod.label;
  const targetChanged = method && initialTargets && method.label !== initialTargets[method.label];
  const canSave = methodChanged || targetChanged || !validInput;
  
  
  return (
    <Card border="light" className="bg-white shadow-sm mb-2 pb-2 mt-3">
      <Card.Body>
        <Col className="justify-content-center">
          <h5 className="mb-4">Notification Method</h5>
          <Form onSubmit={handleSubmit}>
          { (!isLoading && userData) &&
            <>
            <Row>              
              <Col md={5} className="mb-3">
                <Form.Group id="method">
                  <Form.Label>Method 
                  { !pro && <OverlayTrigger
                    key="filter-overlay"
                    placement={"right"}
                    delay={{ hide: 500 }}
                    overlay={renderProTooltip("Upgrade for additional notification methods")}
                  ><Badge pill className="badge-md ms-2">PRO</Badge></OverlayTrigger>}
                  </Form.Label>
                  <Form.Select required id="subreddit" onChange={handleMethodChange} value={method?.id}>
                    {methods.map(m => <option 
                      key={m.id} 
                      value={m.id}
                      name={m.label}
                      disabled={!pro && !m.free}
                    >{m.displayName}{m.id === initialMethod?.id ? ' (current)' : ''}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
              { method?.target && 
              <Col md={7} className="mb-3">
                <Form.Group id="target">
                  <Form.Label>Target</Form.Label>
                  {method.label === 'discord' && 
                    <Form.Control
                      isInvalid={!!targetError}
                      type="text"
                      value={discordTarget}
                      onChange={handleTargetChange}
                      onBlur={() => handleTargetBlur(userData.discord_target)}
                      placeholder={discordTarget}
                    />
                  }
                  {method.label === 'email' && 
                    <Form.Control
                      isInvalid={!!targetError}
                      type="text"
                      value={emailTarget}
                      onChange={handleTargetChange}
                      onBlur={() => handleTargetBlur(userData.email_target)}
                      placeholder={emailTarget}
                    />
                  }
                  {method.label === 'slack' && 
                    <Form.Control
                      isInvalid={!!targetError}
                      type="text"
                      value={slackTarget}
                      onChange={handleTargetChange}
                      onBlur={() => handleTargetBlur(userData.slack_target)}
                      placeholder={slackTarget}
                    />
                  }
                  {method.label === 'webhook' && 
                    <Form.Control
                      isInvalid={!!targetError}
                      type="text"
                      value={webhookTarget}
                      onChange={handleTargetChange}
                      onBlur={() => handleTargetBlur(userData.webhook_target)}
                      placeholder={webhookTarget}
                    />
                  }
                  <Form.Control.Feedback type="invalid">{targetError}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              }
            </Row>
            <Row>
              <Col xs={3} sm={2} xl={1} className="mb-3 align-bottom">
                <Form.Group>
                  {/* <Form.Label>&nbsp;</Form.Label> */}
                  <div>
                    <Button disabled={tested || !validInput } className="px-3" variant="secondary" onClick={handleTest}>Test</Button>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={3} sm={2} xl={1} className="mb-3 align-bottom">
              {/* <Form.Label>&nbsp;</Form.Label> */}
                <div className="ms-2">
                  <Button disabled={submitting || !canSave || !validInput} className="px-3" variant="primary" type="submit">Save</Button>
                </div>
              </Col>
            </Row>
            {(pro && showInfo) && <Row>
              <Col xs={12} sm={12} md={12} className="">
                { method.label === "discord" && ( !discordTarget ?
                  <Alert className="pb-0" variant="warning">To use Discord notification, you must:
                  <ol>
                    <li>
                      Join the <Alert.Link variant={"link"} href={discordInviteUrl}>mrktwtchr Discord server</Alert.Link> 
                    </li>
                    <li>
                      Enter your Discord user id (not @ user name), or <Alert.Link variant={"link"} href={AppRoutes.Discord.path}>authorize the mrktwtchr Bot</Alert.Link> and we'll grab it for you 
                    </li>  
                  </ol>
                  </Alert>
                  :
                  <Alert className="" variant="info"><Alert.Link variant={"link"} href={AppRoutes.Discord.path}>Re-authorize the mrktwtchr Bot</Alert.Link> to change accounts</Alert>
                )}
              </Col>
            </Row>}
            </>
            }
          </Form>
        </Col>
      </Card.Body>
    </Card>

  );
};