// src/components/DiscordOauth.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMessageBar } from '../contexts/MessageBarContext';
import Preloader from './Preloader';
import { useUser } from "../contexts/userContext";


const StripeCheckout = () => {
  const { token, pro, getCheckoutSession } = useUser();
  const { showMessage } = useMessageBar();
  const navigate = useNavigate();
  
  useEffect(() => {

    if (token && !pro) {
      getCheckoutSession();
    } else {
      showMessage("You're already subscribed!")
      // refreshToken(true)
      navigate('/dashboard')
      
    }
    
  }, []);

  return (
    <Preloader show={true} message="Redirecting to Stripe..." />
  );
};

export default StripeCheckout;
