import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from "./Preloader";
import { useUser } from "../contexts/userContext";
import useAxiosInstance from '../utils/axiosInstance';
import { useMessageBar } from '../contexts/MessageBarContext';

const DiscordCallback = () => {
  
  const { token, isLoggedIn, userData, updateUserData } = useUser();
  const { showMessage } = useMessageBar();
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosInstance(token);
  const [usedCode, setUsedCode] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // console.log(JSON.stringify(searchParams))
    const code = searchParams.get('code', null);
    const error = searchParams.get('error', null);
    
    const exchangeCodeForToken = async () => {
      try {
        const response = await axios.post('/auth/discord', {code}, {
          headers: {
             "Authorization" : `Bearer ${token}`
           }
        });
        if ('data' in response && response.data !== null) {
          setUsedCode(code);
          const data = response.data
          if (response.status >= 200 && response.status < 300) {
            const id = data.discord_id || null;
            if (id) {
              await updateUserData({
                'notification_method': 'discord',
                'discord_target': id
              });
              navigate('/dashboard/settings');
            } 
            else {
              showMessage("Error in Discord auth!", 'danger');
              navigate('/dashboard/settings');
            }
          } else {
            // console.error("Did not get an OK response from API");
            showMessage('Bad response from API. Please try again!', 'danger');
            navigate('/dashboard/settings');
          }
        } else {
          // console.error('No data in response', JSON.stringify(response));
          showMessage('Bad response from API. Please try again!', 'danger');
          navigate('/dashboard/settings');
        }
      } catch (error) {
        // console.error('Error during Discord auth:', error);
        showMessage('Error during Discord auth. Please try again!', 'danger');
        navigate('/dashboard/settings');
      }
    }

    if (error) {
      // const errorMessage = searchParams.get('error_description', null);
      // console.error('Error during Discord authentication:', error, errorMessage);
      showMessage('Error during Discord authentication. Please try again');
      navigate('/dashboard/settings');
    } else if (code) {
      if (code !== usedCode) {
        exchangeCodeForToken();
      }
    } else {
      showMessage('Error during Discord authentication. Please try again');
      navigate('/dashboard/settings');
    }

  },[]);

  

  return (
    <Preloader show={!isLoggedIn} />
  );
};

export default DiscordCallback;
