import React, { useState, useEffect, Navigate } from 'react';
import { Route, Routes, Redirect } from "react-router-dom";
import { useUser } from '../contexts/userContext';
import {Routes as AppRoutes} from "../routes" ;


// pages
import Presentation from "./Presentation";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
// import Contact from "./Contact";
import Alert from "./Alert";
import NotFoundPage from "./NotFound";
import ForbiddenPage from "./Forbidden";
import ServerError from "./ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import RedditOauth from '../components/RedditOauth';
import RedditCallback from '../components/RedditCallback';
import DiscordOauth from '../components/DiscordOauth';
import DiscordCallback from '../components/DiscordCallback';
import StripeCallback from '../components/StripeCallback';
import StripeCheckout from '../components/StripeCheckout';
import RequireAuth from '../components/RequireAuth';
import Unauthorized from './Unauthorized';
import Logout from './Logout';
import About from './AboutPage';
import Contact from "./ContactPage";


const RouteWithLoader = ({ component: Component }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      <Component />
    </>
  );
};

const RouteWithSidebar = ({ component: Component }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <Preloader show={!loaded} />
      <Sidebar />
      <main className="content">
        <div className="main-layout d-flex flex-column min-vh-100">
          <Navbar />
          <Component />
          <Footer />
        </div>
      </main>
    </>
  );
};



const HomePage = () => {
  return (
    <Routes>
      <Route exact path={AppRoutes.Presentation.path} element={<RouteWithLoader component={Presentation} />} />
      <Route exact path={AppRoutes.About.path} element={<RouteWithLoader component={About} />} />
      <Route exact path={AppRoutes.Contact.path} element={<RouteWithLoader component={Contact} />} />
      {/* <Route exact path={AppRoutes.Contact.path} element={<RouteWithLoader component={Contact} />} /> */}
      <Route exact path={AppRoutes.NotFound.path} element={<RouteWithLoader component={NotFoundPage} />} />
      
      <Route exact path={AppRoutes.ServerError.path} element={<RouteWithLoader component={ServerError} />} />
      <Route exact path={AppRoutes.Login.path} element={<RouteWithLoader component={RedditOauth} />} />
      <Route exact path={AppRoutes.Discord.path} element={<RouteWithLoader component={DiscordOauth} />} />
      <Route exact path="/auth/reddit" element={<RouteWithLoader component={RedditCallback} />} />
      <Route exact path="/auth/discord" element={<RouteWithLoader component={DiscordCallback} />} />
      <Route exact path="/stripe" element={<RouteWithLoader component={StripeCallback} />} />
      <Route exact path="/upgrade" element={<RouteWithLoader component={StripeCheckout} />} />

      {/* pages */}
      <Route exact path={AppRoutes.Dashboard.path} element={<RouteWithSidebar component={Dashboard} />} />
      <Route exact path={AppRoutes.Settings.path} element={<RouteWithSidebar component={Settings} />} />
      <Route exact path={AppRoutes.Alert.path} element={<RouteWithSidebar component={Alert} />} />
      <Route exact path={AppRoutes.Logout.path} element={<RouteWithSidebar component={Logout} />} />
      <Route exact path={AppRoutes.Unauthorized.path} element={<RouteWithSidebar component={Unauthorized} />} />
      <Route path="*" element={<RouteWithSidebar component={NotFoundPage} />} />
    </Routes>
)};

export default HomePage;
