import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from "./Preloader";
import { useUser } from "../contexts/userContext";
import useAxiosInstance from '../utils/axiosInstance';
import { useMessageBar } from '../contexts/MessageBarContext';

const RedditCallback = () => {
  
  const { login, isLoggedIn } = useUser();
  const { showMessage } = useMessageBar();
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosInstance();
  const [usedCode, setUsedCode] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code', null);
    const state = searchParams.get('state', null);
    const error = searchParams.get('error', null);
    

    const exchangeCodeForToken = async () => {
      try {
        const response = await axios.post('/auth/reddit', {code, state});
        if ('data' in response && response.data !== null) {
          setUsedCode(code);
          const data = response.data
          if (response.status >= 200 && response.status < 300) {
            const jwtToken = data.token || null;
    
            if (jwtToken) {
              await login(jwtToken);
              navigate('/dashboard');
            } 
            else {
              showMessage("Error logging in. Please try again!", 'danger');
              //navigate('/');
            }
          } else {
            console.error("Did not get an OK response from API");
            showMessage('Bad response from API. Please try again!', 'danger');
            // navigate('/');
          }
        } else {
          // console.error('No data in response', JSON.stringify(response));
          showMessage('Bad response from API. Please try again!', 'danger');
          // navigate('/');
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        showMessage('Error during authentication. Please try again!', 'danger');
        // navigate('/');
      }
    }

    if (error) {
      console.error('Error during Reddit authentication:', error);
      showMessage('Error during Reddit authentication. Please try again', 'danger');
      
      navigate('/');
    } else if (code && state) {
      if (code !== usedCode) {
        exchangeCodeForToken();
      }
    } else {
      showMessage('Error during Reddit authentication. Please try again', 'danger');
      navigate('/');
    }

  },[]);

  return (
    <Preloader show={!isLoggedIn} message="Comin' in hot!"/>
  );
};

export default RedditCallback;
