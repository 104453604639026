import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from "./Preloader";
import { useUser } from "../contexts/userContext";
import { useMessageBar } from '../contexts/MessageBarContext';

const StripeCallback = () => {
  
  const { isLoggedIn, updateUser, refreshToken } = useUser();
  const { showMessage } = useMessageBar();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect( () => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success', false) === 'true';
    const session_id = searchParams.get('session_id', null);
    
    if (success) {
      
      const refresh = async () => {
        await refreshToken(true);
        // await updateUser({'plan': 'free'});
        navigate('/dashboard'); // TODO: make a thank you page?
        showMessage('Welcome to the big leagues!\nThanks for subscribing!', 'success', 20000);
      }
      refresh();
    } else {
      navigate('/dashboard');
      showMessage('Hey, maybe next time!', 'warning');
    }

  },[]);

  return (
    <Preloader show={!isLoggedIn} message="Big things coming!"/>
  );
};

export default StripeCallback;
