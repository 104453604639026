// src/components/MessageBar.js
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useMessageBar } from '../contexts/MessageBarContext';

const MessageBar = () => {
  const { message, setMessage, messageType, setMessageType } = useMessageBar();
  // console.log('message bar called')

  const handleClose = () => {
    setMessage(null);
    setMessageType(null);
  }

  return (
    <div className='p-0 position-fixed top-0 start-0 end-0 d-flex justify-content-center alert-dismissible' style={{zIndex: 1050}}>
    <Row className="w-100" >
      <Col xs={12} md={{ span: 8, offset: 2 }}>
        {message && (
        <Alert variant={messageType} className="text-center pointer-event-auto m-3" style={{ minWidth: '300px' }}>
          {message}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleClose}></button>
        </Alert>
        )}
        </Col>
    </Row>
    </div>
    
  );
};

export default MessageBar;
