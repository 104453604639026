import { useState, useEffect } from "react";
import Avatar from "boring-avatars";


const UserAvatar = (name) => {
  const [clipPathId, setClipPathId] = useState('');

  useEffect(() => {
    setClipPathId(`clip-path_${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  return (
    <svg width={40} height={40}>
      <defs>
        <clipPath id={clipPathId}>
          <circle cx="20" cy="20" r="20" />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipPathId})`}>
        <Avatar
          size={40}
          name={name}
          variant={"beam"}
          colors={["#00A0B0", "#6A4A3C", "#CC333F", "#EB6841", "#EDC951"]}
          square={false}
          circle
        />
      </g>
    </svg>
  );
};

export default UserAvatar;