import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Button, Container,  Navbar } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import moment from "moment-timezone";
import CookieConsent from "react-cookie-consent";


import { Routes } from "../routes";
import { useUser } from "../contexts/userContext"
import SelectTagline from "../data/taglines";
import About from "../components/About";

const currentYear = moment().get("year");

const Legal = () => {
  const { isLoggedIn } = useUser();

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand className="me-lg-3 d-flex align-items-center">
            <div className="binoc-logo-icon"><FontAwesomeIcon icon={faBinoculars} className="me-1" /></div>
            <span className="ms-2 brand-text d-none d-md-inline">M R K T W T C H R</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            { isLoggedIn ?
              <Button as={HashLink} to={Routes.Dashboard.path} variant="outline-white" className="ms-3 animate-hover">Dashboard <FontAwesomeIcon icon={faSignInAlt} className="ms-1 animate-right-3" /></Button>
            : 
              <Button as={HashLink} to={Routes.Login.path} variant="outline-white" className="ms-3"><FontAwesomeIcon icon={faSignInAlt} className="me-1" /> Sign In</Button>
            }
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-3 pt-lg-4 pb-5 pb-lg-5 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="fw-bolder text-secondary">A B O U T</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-md" id="legal">
        <Container>
          <Row>
            <Col>
              <About />
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={HashLink} to="/" className="me-lg-3 mb-3 d-flex align-items-center">
                {/* <div className="binoc-logo-icon"><FontAwesomeIcon icon={faBinoculars} className="ms-1" /></div> */}
                <span className="brand-text">M R K T W T C H R</span>
              </Navbar.Brand>
              <p>MRKTWTCHR is a notification serivce for exchange subreddits. Think of us as your personal shopping ninja, stealthily stalking subreddits for those sweet, sweet deals. Just kick back, relax, and let the notifications roll in.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <ul className="links-vertical mt-2">
                <li><Card.Link href={Routes.About.path}>About</Card.Link></li>
                <li><Card.Link href={Routes.Contact.path}>Contact</Card.Link></li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link href={Routes.Presentation.path} className="d-flex justify-content-center">
              </Card.Link>
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © <span className="current-year">{currentYear}</span> | MRKTWTCHR. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <CookieConsent acceptOnScroll={true} acceptOnScrollPercentage={50} >
          MRKTWTCHR uses cookies for essential website functionality only. 
        </CookieConsent>
      </footer>
    </>
  );
};

export default Legal;