import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle, faRocket, faBinoculars, faSignInAlt, } from "@fortawesome/free-solid-svg-icons";
import { faRedditAlien } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Button, Container, ListGroup, Navbar, Nav, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import moment from "moment-timezone";
import CookieConsent from "react-cookie-consent";

import { useUser } from "../contexts/userContext"

import { Routes } from "../routes";
import SelectTagline from "../data/taglines";



const currentYear = moment().get("year");

const Presentation = () => {
  const { isLoggedIn, userData, name, plan, pro, getCheckoutSession } = useUser();

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand className="me-lg-3 d-flex align-items-center">
            <div className="binoc-logo-icon"><FontAwesomeIcon icon={faBinoculars} className="me-1" /></div>
            <span className="ms-2 brand-text d-none d-md-inline">M R K T W T C H R</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {/* <Navbar.Collapse id="navbar-default-secondary">
              <Nav className="navbar-nav-hover align-items-lg-center">
              { isLoggedIn && ( plan !== "pro" ?
                <Nav.Link as={HashLink} variant="light" to="#upgrade">Features</Nav.Link>
              :
                <Badge bg="tertiary" className="plan ms-2 ps-3 pe-3 pt-1 pb-1">
                  {plan.toUpperCase()}
                </Badge>
              )}
              </Nav>
            </Navbar.Collapse> */}
            { isLoggedIn ?
              <Button as={HashLink} to={Routes.Dashboard.path} variant="outline-white" className="ms-3 animate-hover">Dashboard <FontAwesomeIcon icon={faSignInAlt} className="ms-1 animate-right-3" /></Button>
            : 
              <Button as={HashLink} to={Routes.Login.path} variant="outline-white" className="ms-3"><FontAwesomeIcon icon={faSignInAlt} className="me-1" /> Sign In</Button>
            }
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-3 pt-lg-4 pb-4 pb-lg-6 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="fw-bolder text-secondary">M R K T W T C H R</h1>
              <h3 className="fw-light mt-2 mb-5">
                <SelectTagline/>
              </h3>
              <div className="d-flex-column align-items-center justify-content-center">
              { isLoggedIn ?
                <>
                  <h3 className="fw-light mt-2 mb-1">Welcome back, <span className="fw-bolder">{name}</span>!</h3>
                  <Button as={Link} to={Routes.Dashboard.path} variant="secondary" style={{ minWidth: '220px' }} className="w-25 m-0 mt-3 mb-3 animate-hover">Go to your Dashboard <FontAwesomeIcon icon={faSignInAlt} className="animate-right-3 ms-1" /></Button>
                </>
                :
                <Button as={Link} to={Routes.Login.path} variant="secondary" style={{ minWidth: '235px' }} className="w-25 m-0 mt-3 mb-3">Sign up free with Reddit <FontAwesomeIcon icon={faRedditAlien} className="ms-1 animate-pulse-1" /></Button>
              }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-lg bg-white" id="upgrade">
        <Container>
          <Row>
            {/* <Col xs={12} lg={8}>
              <h2 className="fw-light mb-3">Upgrade to PRO</h2>
            </Col> */}
            <Col xs={12} lg={4}>
              <div className="binoc-big-icon pt-0">
                <FontAwesomeIcon icon={faBinoculars} color="#92cbdb"/>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} md={6} lg={4} className="mb-5 mb-lg-0">
              <Card border="light" className="p-4 py-5 mt-lg-n5">
                <Card.Header className="bg-white border-0 pb-0">
                  <span className="d-block">
                    <h2 className="text-primary fw-bold align-top">Free</h2>
                  </span>
                </Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush price-list">
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>1</strong> Alert
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>1</strong> Alert Method
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>8+</strong> Exchange Subreddits
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> Advanced Search
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                <Button href="/login" target="_blank" variant="primary" className="w-100 m-0 mt-3 mb-3">Sign up with Reddit <FontAwesomeIcon icon={faRedditAlien} className="ms-1" /></Button>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4} className="mb-5 mb-lg-0">
              <Card border="dark" className="p-4 py-5 mt-lg-n5">
                <Card.Header className="bg-white border-0 pb-0">
                  <span className="d-block">
                    <h2 className="text-primary fw-bold align-top">PRO</h2>
                  </span>
                </Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush price-list">
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>10</strong> Alerts
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>5+</strong> Alert Methods
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>8+</strong> Exchange Subreddits
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Advanced Search
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                <Button  href="/login" variant="secondary" className="w-100 m-0 mt-3 mb-3">Upgrade for all features <FontAwesomeIcon icon={faRocket} className="ms-1" /></Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={HashLink} to="/" className="me-lg-3 mb-3 d-flex align-items-center">
                {/* <div className="binoc-logo-icon"><FontAwesomeIcon icon={faBinoculars} className="ms-1" /></div> */}
                <span className="brand-text">M R K T W T C H R</span>
              </Navbar.Brand>
              <p>MRKTWTCHR is a notification serivce for exchange subreddits. Think of us as your personal shopping ninja, stealthily stalking subreddits for those sweet, sweet deals. Just kick back, relax, and let the notifications roll in.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <ul className="links-vertical mt-2">
                <li><Card.Link href={Routes.About.path}>About</Card.Link></li>
                <li><Card.Link href={Routes.Contact.path}>Contact</Card.Link></li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link href={Routes.Presentation.path} className="d-flex justify-content-center">
              </Card.Link>
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © <span className="current-year">{currentYear}</span> | MRKTWTCHR. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <CookieConsent acceptOnScroll={true} acceptOnScrollPercentage={50} >
          MRKTWTCHR uses cookies for essential website functionality only.
        </CookieConsent>
      </footer>
    </>
  );
};

export default Presentation;